import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Util} from '../_utils/util';
import {User} from '../_models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class AuthService {
	private static readonly CURRENT_USER_KEY = 'currentUser';
	// token expires in 10hours
	private static readonly TOKEN_EXPIRATION = 10 * 60 * 60;
	private baseUrl: string;
	private loginUrl = '/auth';

	constructor(private router: Router,
				private http: HttpClient,
				private util: Util,
				private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl;
	}

	loginUser(username: string, password: string): Observable<User> {
		const bodyString = JSON.stringify({'username': username, 'password': password});
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const requestUrl = this.baseUrl + this.loginUrl;
		const options = {headers: headers};

		return this.http.post(requestUrl, bodyString, options).pipe(
			map((response: Response) => {
				const user: any = response;
				if (user && user.token) {
					this.saveUser(user);
				}
				return user;
			}));
	}

	logoutUser() {
		document.cookie = `${AuthService.CURRENT_USER_KEY}=''; max-age=-1;`;
		this.router.navigate(['/login']);
	}

	getToken() {
		const currentUser = this.loadUser();
		return currentUser != null ? currentUser.token : null;
	}

	getRoles(): string[] {
		const currentUser = this.loadUser();
		return currentUser != null ? currentUser.roles : null;
	}

	isAdmin(): boolean {
		const roles: string[] = this.getRoles();
		return !roles.includes('ROLE_MARKETING_SPECIALIST');
	}

	isAllowedRefund(): boolean {
		const roles: string[] = this.getRoles();
		return roles.includes('ROLE_STRIPE_REFUND');
	}

	hasAccountingRole(): boolean {
		const roles: string[] = this.getRoles();
		return roles.includes('ROLE_ACCOUNTING');
	}

	isAuthenticated() {
		return this.getToken() != null;
	}


	saveUser(user: User): void {
		// setting a 10 hours expiration
		document.cookie = `${AuthService.CURRENT_USER_KEY}=${JSON.stringify(user)}; max-age=${AuthService.TOKEN_EXPIRATION};`;
	}

	loadUser(): User {
		return JSON.parse(this.util.getCookie(AuthService.CURRENT_USER_KEY));
	}

	getOptions(): { headers: HttpHeaders } {
		const token = this.getToken();
		if (token == null) {
			alert('token has expired, logging out....');
			this.router.navigate(['/login']);
		}

		return {
			headers: new HttpHeaders({
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': token
			})
		};
	}

	getTextOptions(): { headers: HttpHeaders } {
		const token = this.getToken();
		if (token == null) {
			alert('token has expired, logging out....');
			this.router.navigate(['/login']);
		}

		return {
			headers: new HttpHeaders({
				'Accept': 'application/json',
				'Content-Type': 'text/uri-list',
				'Authorization': token
			})
		};
	}

	getDefaultOptions(): any {
		const token = this.getToken();
		if (token == null) {
			alert('token has expired, logging out....');
			this.router.navigate(['/login']);
		}

		return {
			responseType: 'text',
			headers: new HttpHeaders({
				'Authorization': token,
				'Accept': 'text/plain;charset=UTF-8',
			})
		};
	}
}
