import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSort, MatTableDataSource} from '@angular/material';

import {Loader} from '../../_models/loader';
import {AccountsReceivableService} from '../../_services/accounts-receivable.service';
import {
	InvoiceDetails,
	InvoiceDetailsMiscRevenue,
	InvoiceDetailsPayment,
	InvoiceDetailsReservation
} from '../../_models/accounts-receivable.model';


@Component({
	selector: 'app-ar-invoice-detail',
	templateUrl: './ar-invoice-detail.component.html',
	styleUrls: ['./ar-invoice-detail.component.scss']
})
export class ArInvoiceDetailComponent implements OnInit, AfterViewInit {

	dataSourceReservations = new MatTableDataSource<InvoiceDetailsReservation>();
	dataSourceMiscRevenues = new MatTableDataSource<InvoiceDetailsMiscRevenue>();
	dataSourcePayments = new MatTableDataSource<InvoiceDetailsPayment>();
	loader = new Loader();
	invoiceId = 0;
	invoiceDetail: InvoiceDetails = {
		id: 0,
		arVendorName: '',
		arVendorId: 1,
		invoiceDate: new Date(),
		amountPaid: 0,
		amountTotal: 0,
		reservations: [],
		miscRevenues: [],
		payments: []
	}

	displayedColumnsReservations: string[] = ['reservationId', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'amountInvoiced', 'amountPaid', 'amountUnpaid'];

	displayedColumnsMiscRevenues: string[] = ['confirmNum', 'plAdjustmentImportId', 'amountInvoiced', 'amountPaid', 'amountUnpaid'];

	displayedColumnsPayments: string[] = ['id', 'arVendorId', 'paymentDate', 'totalAmount'];

	@ViewChild('sortPayments', {static: false}) sortPayments!: MatSort;
	@ViewChild('sortReservations', {static: false}) sortReservations!: MatSort;
	@ViewChild('sortMiscRevenues', {static: false}) sortMiscRevenues!: MatSort;

	constructor(private readonly accountsReceivableService: AccountsReceivableService,
	            private readonly router: Router,
	            private readonly route: ActivatedRoute,
	            private readonly location: Location) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			const id = params.get('id');
			this.invoiceId = id ? +id : null;
			console.log('Invoice ID:', this.invoiceId);
		});
		this.loader.isLoading = false;
	}

	ngAfterViewInit() {
		console.log('afterViewInit');
		this.accountsReceivableService.getInvoiceDetails(this.invoiceId).subscribe({
			next: invoice => {
				this.loader.isLoading = false;
				this.invoiceDetail = invoice;
				this.dataSourceReservations.data = invoice.reservations;
				this.dataSourceMiscRevenues.data = invoice.miscRevenues;
				this.dataSourcePayments.data = invoice.payments;
			}
		})
		this.dataSourcePayments.sort = this.sortPayments;
		this.dataSourceReservations.sort = this.sortReservations;
	}

	onCreatePayment() {
		this.router.navigate(['/admin/invoices/' + this.invoiceId + '/ar-payment-create']);
	}

	onBackButtonPressed() {
		this.location.back()
	}
}
