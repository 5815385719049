import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AppConfigService} from './app-config.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class InfoBackendService {

	private readonly baseUrl: string;

	constructor(private readonly httpClient: HttpClient,
	            private readonly authService: AuthService,
	            private readonly environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/info';
	}

	getBeVersion(): Observable<string> {
		return this.httpClient.get<any>(this.baseUrl + '/version', this.authService.getOptions())
            .pipe(
                map(res => res.app_version)
            );
	}
}
