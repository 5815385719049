import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';

import {Loader} from '../../_models/loader';
import {AccountsReceivableService} from '../../_services/accounts-receivable.service';
import {
	ArInvoiceRequest,
	ArInvoiceRequestLine,
	ArVendor,
	ReservationResponse
} from '../../_models/accounts-receivable.model';
import {ExcelService} from '../../_services/excel-service';
import moment from 'moment';


@Component({
	selector: 'app-ar-invoice-create',
	templateUrl: './ar-invoice-create.component.html',
	styleUrls: ['./ar-invoice-create.component.scss']
})
export class ArInvoiceCreateComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort, {static: false}) set contentMain(sort: MatSort) {
		this.dataSourceMain.sort = sort;
	}

	@ViewChild(MatSort, {static: false}) set contentPreview(sort: MatSort) {
		this.dataSourcePreview.sort = sort;
	}

	dataSourceMain = new MatTableDataSource<ReservationResponse>();
	dataSourcePreview = new MatTableDataSource<ReservationResponse>();
	allReservations: ReservationResponse[] = []
	selectAllChecked = false;
	selectedReservations: ReservationResponse[] = [];
	presentPreview = false
	filtersActivePreviousState = false;
	filterSelected = false;
	loader = new Loader();
	arVendors: ArVendor[] = [];
	invoiceTotal = 0;
	invoiceDate = new Date();

	searchCriteria = {
		confirmationCode: '', brandId: '', brandName: '', dropoffDateFrom: '', dropoffDateTo: '', reservationDateFrom: '', reservationDateTo: ''
	};

	displayedColumnsMain: string[] = ['select', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'cancelled', 'glArNotInvoiced'];

	displayedColumnsPreview: string[] = ['brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'cancelled', 'glArNotInvoiced'];

	private _selectedArVendorId = '';
	selectedArVendor: ArVendor;

	constructor(private readonly accountsReceivableService: AccountsReceivableService,
	            private readonly excelService: ExcelService,
	            private readonly router: Router) {
	}

	ngOnInit(): void {
        this.loader.isLoading = false;
    }

	ngAfterViewInit() {
		this.accountsReceivableService.getAllArVendors().subscribe({
			next: data => {
				this.arVendors = data.filter(v => v.commProviderId === null && [2, 5, 6, 7].includes(v.syndicationTypeId));
			}
		})
	}

	get selectedArVendorId() {
		return this._selectedArVendorId;
	}

	set selectedArVendorId(value: string) {
		if (this._selectedArVendorId !== value) {
			this._selectedArVendorId = value;

			this.selectAllChecked = false;

			this.dataSourceMain.data.forEach(res => res.selected = false)
			console.log('vendor selected ' + this.selectedArVendorId);
			this.loader.isLoading = true;
			this.accountsReceivableService.getUnInvoicedReservations(this._selectedArVendorId).subscribe({
				next: reservations => {
					this.loader.isLoading = false;
					this.allReservations = reservations;
					this.dataSourceMain.data = reservations;
					this.dataSourceMain.filterPredicate = this.createFilter()
				}
			})
		}
	}


	toggleSelectAll() {
		this.selectAllChecked = !this.selectAllChecked;
		const filteredData = this.dataSourceMain.filteredData;
		filteredData.forEach(row => row.selected = this.selectAllChecked);
		this.updateInvoiceTotal()
	}

	toggleFilterSelected() {
		this.filterSelected = !this.filterSelected;
		this.selectedReservations = this.dataSourceMain.data.filter(res => res.selected);
		if (this.filterSelected) {
			this.dataSourceMain.data = this.selectedReservations;
			this.dataSourceMain.filter = null;
		} else {
			this.dataSourceMain.data = this.allReservations;
			this.dataSourceMain.filter = JSON.stringify(this.searchCriteria);
		}
	}

	createPreviewFromSelection() {
		console.log('selected ar vendor: ' + this.selectedArVendorId);
		this.selectedReservations = this.dataSourceMain.data.filter(res => res.selected);

		this.invoiceTotal = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.glArNotInvoiced, 0);

		this.selectedArVendor = this.findVendorById(this.selectedArVendorId)

		this.presentPreview = true;
		this.dataSourcePreview.data = this.selectedReservations;
	}

	createInvoiceFromPreview() {
		const lines: ArInvoiceRequestLine[] = [];
		for (const reservation of this.selectedReservations) {
			lines.push({
				reservation_id: reservation.id,
				pl_adjustment_import_id: null,
				amount: reservation.glArNotInvoiced,
				status: 'HONORED'
			})
		}
		const invoiceRequest: ArInvoiceRequest = {
			invoice_date: this.invoiceDate,
			ar_vendor_id: +this.selectedArVendorId,
			lines: lines
		};

		this.accountsReceivableService.createArInvoice(invoiceRequest)
		.subscribe((response) => {
			console.log('HTTP Status Code:', response.statusCode)
			if (response.statusCode === 200) {
				console.log('Invoice was created');
				this.router.navigate([`/admin/ar-invoice-list`]);
			}
		},         (error) => {
			console.log(error);
		});
	}

	onBackButtonPressed() {
		this.presentPreview = false;
	}

	filterChange() {
		if (!this.filtersActivePreviousState && this.filtersActive()) {
			this.selectAllChecked = false;
			// this.dataSourceMain.data.forEach(res => res.selected = false);
		}
		this.dataSourceMain.filter = JSON.stringify(this.searchCriteria);
	}

	get isCreatePreviewDisabled(): boolean {
		return this.dataSourceMain.data.filter(res => res.selected).length === 0 || this.selectedArVendorId === '';
	}

	findVendorById(id: string): ArVendor | undefined {
		return this.arVendors.find(vendor => vendor.id === +id);
	}

	updateInvoiceTotal() {
		this.selectedReservations = this.dataSourceMain.data.filter(res => res.selected);
		this.invoiceTotal = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.glArNotInvoiced, 0);
	}

	exportAsXlsx(data, fileName): void {
		this.excelService.exportAsExcelFile(data.map(reservation => {
				return {
					id: reservation.id,
					brand_id: reservation.brandId,
					brand_name: reservation.brandName,
					confirmation_code: reservation.confirmationCode,
					reservation_date: moment(reservation.reservationDate).format('MM/DD/YYYY'),
					pickup_date: moment(reservation.pickupDate).format('MM/DD/YYYY'),
					dropoff_date: moment(reservation.dropoffDate).format('MM/DD/YYYY'),
					pretax_cost: reservation.pretaxCost,
					total_cost: reservation.totalCost,
					currency_code: reservation.currencyCode,
					cancelled: reservation.cancelled,
					invoice_amount: reservation.glArNotInvoiced,

				}
			}),
			fileName + '_' + new Date().getTime());
	}

	private filtersActive(): boolean {
		return !!this.dataSourceMain.data.filter;
	}

	private createFilter(): (data: ReservationResponse, filter: string) => boolean {
		return (data: ReservationResponse, filter: string) => {
					const criteria = JSON.parse(filter);

					// Filter for confirmation code
					const matchesConfirmationCode = criteria.confirmationCode ?
						data.confirmationCode.toLowerCase().includes(criteria.confirmationCode.toLowerCase()) : true;

					// Filter for brand ID
					const matchesBrandId = criteria.brandId ?
						data.brandId.toString().toLowerCase().includes(criteria.brandId.toLowerCase()) : true;

					// Filter for brand name
					const matchesBrandName = criteria.brandName ?
						data.brandName.toLowerCase().includes(criteria.brandName.toLowerCase()) : true;

					// Filter for dropoff date range
					const matchesDropoffDate = criteria.dropoffDateFrom || criteria.dropoffDateTo ? (() => {
						const dropoffDate = new Date(data.dropoffDate);
						const fromDate = criteria.dropoffDateFrom ? new Date(criteria.dropoffDateFrom) : null;
						const toDate = criteria.dropoffDateTo ? new Date(criteria.dropoffDateTo) : null;

						if (fromDate && dropoffDate < fromDate) {
							return false;
						}
						return !(toDate && dropoffDate > toDate);

					})() : true;

					// Filter for reservation date range
					const matchesReservationDate = criteria.reservationDateFrom || criteria.reservationDateTo ? (() => {
						const reservationDate = new Date(data.reservationDate);
						const fromDate = criteria.reservationDateFrom ? new Date(criteria.reservationDateFrom) : null;
						const toDate = criteria.reservationDateTo ? new Date(criteria.reservationDateTo) : null;

						if (fromDate && reservationDate < fromDate) {
							return false;
						}
						return !(toDate && reservationDate > toDate);

					})() : true;

					return matchesConfirmationCode && matchesBrandId && matchesBrandName && matchesDropoffDate && matchesReservationDate;
				}
	}
}
