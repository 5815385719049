import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap-invoice-list',
  templateUrl: './ap-invoice-list.component.html',
  styleUrls: ['./ap-invoice-list.component.scss']
})
export class ApInvoiceListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
