import {Component} from '@angular/core';
import {AuthService} from '../../_services/auth.service';
import {AppConfigService} from '../../_services/app-config.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent {

	constructor(private authService: AuthService) {
	}

	getAuthService() {
		return this.authService;
	}

	onLogout() {
		this.authService.logoutUser();
	}

	onOption1() {
		console.log('onOption1 called');
	}

	onOption2() {
		console.log('onOption2 called');
	}
}
