import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AppConfigService} from './app-config.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
	ArCommissionStatusCreateRequest,
	ArCommissionStatusValidateRequest,
	ArInvoiceCreateResponse,
	ArInvoiceRequest,
	ArInvoiceResponse,
	ArPaymentRequest,
	ArRecordMiscRevenueRequest,
	ArRecordMiscRevenueResponse,
	ArStatusReportValidationResponse,
	ArVendor,
	InvoiceDetails,
	ReservationResponse
} from '../_models/accounts-receivable.model';
import moment from 'moment';


@Injectable({providedIn: 'root'})
export class AccountsReceivableService {
	private readonly baseUrl: string;

	constructor(private readonly httpClient: HttpClient,
	            private readonly authService: AuthService,
	            private readonly environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/accounting/receivables';
	}

	createMiscBookingRevenue(data: ArRecordMiscRevenueRequest): Observable<ArRecordMiscRevenueResponse[]> {
		return this.httpClient
			.post(`${this.baseUrl}/revenue/misc`, JSON.stringify(data), this.authService.getOptions())
			.pipe(
				map((rows: any[]) => rows.map(row => ({
					confirmNum: row.confirm_num,
					plAdjustmentImportId: row.pl_adjustment_import_id,
					amount: row.amount
				})))
			);
	}

	createArInvoice(data: ArInvoiceRequest): Observable<{ statusCode: number; invoice: ArInvoiceCreateResponse }> {
		return this.httpClient
		.post(`${this.baseUrl}/invoices`, JSON.stringify(data), {
			...this.authService.getOptions(),
			observe: 'response',
		})
		.pipe(
			map((response: any) => ({
				statusCode: response.status,
				invoice: {
					arInvoiceId: response.body.ar_invoice_id,
					arVendorId: response.body.ar_vendor_id,
					totalAmount: response.body.total_amount,
					invoiceDate: moment(response.body.invoice_date, 'YYYY-MM-DD').toDate()
				},
			}))
		);
	}

	createArPayment(data: ArPaymentRequest): Observable<number> {
		return this.httpClient.post(
			`${this.baseUrl}/invoices/reservations/payment`,
			JSON.stringify(data),
			{
				...this.authService.getOptions(),
				observe: 'response'
			})
		.pipe(map(res => res.status));
	}

	validateStatusReport(data: ArCommissionStatusValidateRequest): Observable<ArStatusReportValidationResponse> {
		return this.httpClient.post<any>(`${this.baseUrl}/invoices/reservations/validate`, JSON.stringify(data), this.authService.getOptions())
			.pipe(
				map(response => ({
					foundReservations: this.mapReservationResponse(response.found_reservations),
					notFoundReservations: response.not_found_reservations,
					duplicateReservations: response.duplicate_reservations,
					reservationValidationErrors: response.reservation_validation_errors
				}))
			);
	}

	createStatusReport(data: ArCommissionStatusCreateRequest): Observable<number> {
		return this.httpClient.post(
			`${this.baseUrl}/invoices/reservations/status`,
			JSON.stringify(data), {
				...this.authService.getOptions(),
				observe: 'response'
			})
			.pipe(map(res => res.status));
	}

	getInvoiceDetails(id: number): Observable<InvoiceDetails> {
		console.log('getInvoiceDetails:', id);
		return this.httpClient.get<InvoiceDetails>(`${this.baseUrl}/invoices/${id}`, this.authService.getOptions())
		.pipe(
			map((invoice: any) => {
					console.log('getInvoiceDetails:', invoice);
					return {
						id: invoice.id,
						invoiceDate: moment(invoice.invoice_date, 'YYYY-MM-DD').toDate(),
						arVendorId: invoice.ar_vendor_id,
						arVendorName: invoice.ar_vendor_name,
						amountTotal: invoice.amount_total,
						amountPaid: invoice.amount_paid,
						reservations: invoice.reservations.map((reservation: any) => {
							return {
								reservationId: reservation.reservation_id,
								brandId: reservation.brand_id,
								brandName: reservation.brand_name,
								amountPaid: reservation.amount_paid,
								reservationDate: moment(reservation.reservation_date, 'YYYY-MM-DD').toDate(),
								confirmationCode: reservation.confirmation_code,
								pickupDate: moment(reservation.pickup_date, 'YYYY-MM-DD').toDate(),
								dropoffDate: moment(reservation.dropoff_date, 'YYYY-MM-DD').toDate(),
								pretaxCost: reservation.pretax_cost,
								totalCost: reservation.total_cost,
								amountInvoiced: reservation.amount_invoiced,
								amountUnpaid: reservation.amount_invoiced - reservation.amount_paid
							}
						}),
						payments: invoice.payments.map((payment: any) => {
							return {
								id: payment.id,
								arVendorId: payment.ar_vendor_id,
								paymentDate: moment(payment.payment_date, 'YYYY-MM-DD').toDate(),
								totalAmount: payment.total_amount
							}
						}),
						miscRevenues: invoice.misc_revenues.map((miscRevenue: any) => {
							return {
								confirmNum: miscRevenue.confirm_num,
								plAdjustmentImportId: miscRevenue.pl_adjustment_import_id,
								amountInvoiced: miscRevenue.amount_invoiced,
								amountPaid: miscRevenue.amount_paid,
								amountUnpaid: miscRevenue.amount_invoiced - miscRevenue.amount_paid
							}
						})
					}
				}
			)
		);
	}

	getAllArInvoices() {
		return this.httpClient.get<ArInvoiceResponse[]>(`${this.baseUrl}/invoices`, this.authService.getOptions())
			.pipe(
				map((responses: any[]) => {
					return responses.map(response => {
						return {
							id: response.id,
							invoiceDate: response.invoice_date,
							arVendorId: response.ar_vendor_id,
							arVendorName: response.ar_vendor_name,
							totalAmount: response.total_amount,
							amountPaid: response.amount_paid
						}
					})
				})
			);
	}

	getUnInvoicedReservations(arVendorId: string): Observable<ReservationResponse[]> {
		const headers = this.authService.getOptions()
		const httpOptions = {
			headers: headers.headers,
			params: {'arVendorId': arVendorId}
		};

		return this.httpClient.get<ReservationResponse[]>(`${this.baseUrl}/reservations/uninvoiced`, httpOptions)
		.pipe(
			map((responses: any[]) => {
				return responses.map(response => {
					return {
						id: response.id,
						brandId: response.brand_id,
						brandName: response.brand_name,
						confirmationCode: response.confirmation_code,
						reservationDate: moment(response.reservation_date, 'YYYY-MM-DD').toDate(),
						pickupDate: moment(response.pickup_date, 'YYYY-MM-DD').toDate(),
						dropoffDate: moment(response.dropoff_date, 'YYYY-MM-DD').toDate(),
						pretaxCost: response.pretax_cost,
						totalCost: response.total_cost,
						currencyCode: response.currency,
						cancelled: response.cancelled,
						commission: response.commission,
						estimatedCommission: response.estimated_commission,
						glArNotInvoiced: response.gl_ar_not_invoiced,
						gl_ar_invoice_not_paid: response.gl_ar_invoice_not_paid,
						reported: response.reported,
						amountInvoiced: null,
						amountPaid: null,
						reasonCode: null,
						amountNewInvoice: null,
						amountNewPayment: null
					}
				});
			}));
	}

	getReservationsByConfirmationCodes(arVendorId: string, confirmationCodes: string[]): Observable<ReservationResponse[]> {
		const data = {
			ar_vendor_id: arVendorId,
			confirmation_codes: confirmationCodes
		}
		return this.httpClient.post(`${this.baseUrl}/reservations`, JSON.stringify(data), this.authService.getOptions())
		.pipe(
			map((responses: any[]) => {
				return responses.map(response => {
					return {
						id: response.id,
						brandId: response.brand_id,
						brandName: response.brand_name,
						confirmationCode: response.confirmation_code,
						reservationDate: moment(response.reservation_date, 'YYYY-MM-DD').toDate(),
						pickupDate: moment(response.pickup_date, 'YYYY-MM-DD').toDate(),
						dropoffDate: moment(response.dropoff_date, 'YYYY-MM-DD').toDate(),
						pretaxCost: response.pretax_cost,
						totalCost: response.total_cost,
						currencyCode: response.currency,
						cancelled: response.cancelled,
						commission: response.commission,
						estimatedCommission: response.estimated_commission,
						glArNotInvoiced: response.gl_ar_not_invoiced,
						gl_ar_invoice_not_paid: response.gl_ar_invoice_not_paid,
						reported: response.reported,
						amountInvoiced: response.amount_invoiced,
						amountPaid: response.amount_paid,
						reasonCode: null,
						amountNewInvoice: null,
						amountNewPayment: null
					}
				});
			}));
	}

	getAllArVendors(): Observable<ArVendor[]> {
		return this.httpClient.get<ArVendor[]>(`${this.baseUrl}/vendors`, this.authService.getOptions())
		.pipe(
			map((responses: any[]) => {
				return responses.map(response => {
					return {
						id: response.id,
						name: response.name,
						commProviderId: response.comm_provider_id,
						syndicationTypeId: response.syndication_type_id
					}
				})
			})
		)
	}

	mapReservationResponse(response: Record<string, any>): ReservationResponse[] {
		return Object.values(response).map(r => {
			return {
				id: r.id,
				brandId: r.brand_id,
				brandName: r.brand_name,
				confirmationCode: r.confirmation_code,
				reservationDate: moment(r.reservation_date, 'YYYY-MM-DD').toDate(),
				pickupDate: moment(r.pickup_date, 'YYYY-MM-DD').toDate(),
				dropoffDate: moment(r.dropoff_date, 'YYYY-MM-DD').toDate(),
				pretaxCost: r.pretax_cost,
				totalCost: r.total_cost,
				currencyCode: r.currency,
				cancelled: r.cancelled,
				commission: r.commission,
				estimatedCommission: r.estimated_commission,
				glArNotInvoiced: r.gl_ar_not_invoiced,
				gl_ar_invoice_not_paid: r.gl_ar_invoice_not_paid,
				reported: r.reported,
				amountInvoiced: r.amount_invoiced,
				amountPaid: r.amount_paid,
				reasonCode: null,
				amountNewInvoice: null,
				amountNewPayment: null
			}
		})
	}
}
