import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AccountsReceivableService} from '../../_services/accounts-receivable.service';
import {
	ArCommissionStatusCreateRequest,
	ArCommissionStatusCreateRowRequest, ArCommissionStatusReport,
	ArVendor,
	ReservationResponse
} from '../../_models/accounts-receivable.model';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Loader} from '../../_models/loader';


@Component({
	selector: 'app-ar-reservation-report-create-our-numbers',
	templateUrl: './ar-reservation-report-create-our-numbers.component.html',
	styleUrls: ['./ar-reservation-report-create-our-numbers.component.scss']
})
export class ArReservationReportCreateOurNumbersComponent implements OnInit, AfterViewInit {

	fileContent: string | null = null;
	parsedData: ArCommissionStatusReport[];
	dataSourcePreview = new MatTableDataSource<ReservationResponse>();
	loader = new Loader();
	selectedReservations:  ReservationResponse[] = [];
	presentPreview = false
	arVendors: ArVendor[] = [];
	selectedArVendorId = '';

	selectedArVendor: ArVendor;
	totalAmountInvoiced = 0;
	totalAmountPaid = 0;
	processDate = new Date();

	displayedColumnsPreview: string[] = ['reservationId', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'reasonCode'];

	constructor(private readonly accountsReceivableService: AccountsReceivableService, private readonly router: Router,
				         private readonly snackBar: MatSnackBar) {
	}

	ngOnInit() {
		console.log('ArReservationReportCreateComponent initialized');
		this.loader.isLoading = false;
	}

	ngAfterViewInit() {
		this.accountsReceivableService.getAllArVendors().subscribe({
			next: data => {
				this.arVendors = data.filter(v => v.commProviderId === null && [1, 2, 5, 6, 7].includes(v.syndicationTypeId));
			}
		})
	}

	createPreviewFromData() {
		const confirmationCodes = this.parsedData.map(r => r.confCode);
		this.accountsReceivableService.getReservationsByConfirmationCodes(this.selectedArVendorId, confirmationCodes).subscribe({
			next: reservations => {
				reservations.forEach(r => {
					const reportRow = this.parsedData.find(row => row.confCode === r.confirmationCode);
					if (reportRow) {
						r.reasonCode = reportRow.reasonCode;
					}
					this.selectedReservations = reservations;
				});

				this.totalAmountInvoiced = this.selectedReservations.reduce(
					(total, reservation) => total + reservation.amountNewInvoice, 0);

				this.totalAmountPaid = this.selectedReservations.reduce(
					(total, reservation) => total + reservation.amountNewPayment, 0
				)

				this.selectedArVendor = this.findVendorById(this.selectedArVendorId)
				this.presentPreview = true;
				this.dataSourcePreview.data = this.selectedReservations;
			}
		})
	}

	validateStatusReport() {
		this.loader.isLoading = true;
		const rows: ArCommissionStatusCreateRowRequest[] = [];
		for (const reservation of this.selectedReservations) {
			rows.push({
				reservation_id: reservation.id,
				confirm_num: reservation.confirmationCode,
				reason_code: reservation.reasonCode,
				columns: [{
					revenue_type: 'BOOKING',
					amount: reservation.amountNewInvoice,
				}]
			})
		}
		const statusRequest: ArCommissionStatusCreateRequest = {
			process_date: moment(this.processDate).toDate(),
			ar_vendor_id: this.selectedArVendor.id,
			report_name: `STATUS UPLOAD ${this.selectedArVendor.name}-${new Date().toISOString()}`,
			adjustment_type: 'ADD',
			syndication_type_id: this.selectedArVendor.syndicationTypeId,
			lines: rows
		}
		this.accountsReceivableService.validateStatusReport(statusRequest).subscribe({
			next: response => {
				console.log(response);
				const duplicatedReservationsError = Object.keys(response.duplicateReservations).length > 0;
				const reservationValidationError = Object.keys(response.reservationValidationErrors).length > 0;
				if (duplicatedReservationsError || reservationValidationError) {
					this.loader.isLoading = false;
					this.openSnackBar('Error uploading status report - duplicate reservations or validation errors found', 'error');
				} else {
					this.createStatusReport();
				}
			},
			error: error => {
				this.loader.isLoading = false;
				console.log(error);
			}
		})
	}

	createStatusReport() {
		const rows: ArCommissionStatusCreateRowRequest[] = [];
		for (const reservation of this.selectedReservations) {
			rows.push({
				reservation_id: reservation.id,
				confirm_num: reservation.confirmationCode,
				reason_code: reservation.reasonCode,
				columns: [{
					revenue_type: 'BOOKING',
					amount: reservation.amountNewInvoice,
				}]
			})
		}
		const statusRequest: ArCommissionStatusCreateRequest = {
			process_date: moment(this.processDate).toDate(),
			ar_vendor_id: this.selectedArVendor.id,
			report_name: 'ONLY TESTING',
			adjustment_type: 'ADD',
			syndication_type_id: this.selectedArVendor.syndicationTypeId,
			lines: rows
		}

		this.accountsReceivableService.createStatusReport(statusRequest).subscribe({
			next: (statusCode: number) => {
				this.loader.isLoading = false;
				if (statusCode === 200) {
					console.log('status report uploaded successfully');
					this.initializeData();
					this.openSnackBar('Successfully uploaded report', 'success');
				}
			}
		})
	}

	initializeData() {
		this.presentPreview = false;
		this.selectedArVendorId = undefined;
		this.parsedData = [];
		this.dataSourcePreview.data = [];
		this.selectedReservations = [];
		this.totalAmountInvoiced = 0;
		this.totalAmountPaid = 0;
		this.processDate = new Date();
	}

	onBackButtonPressed() {
		this.presentPreview = false;
	}

	get showFileSelector() {
		return this.selectedArVendorId !== '';
	}

	findVendorById(id: string): ArVendor | undefined {
		return this.arVendors.find(vendor => vendor.id === +id);
	}

	// file select and drag & drop
	onDragOver(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(true);
	}

	onDragLeave(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(false);
	}

	onDrop(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(false);

		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			const file = event.dataTransfer.files[0];

			if (file.type === 'text/csv') {
				this.readFile(file);
			} else {
				alert('Please drop a valid CSV file.');
			}
		} else {
			alert('No file detected. Please drop a valid file.');
		}
	}

	onFileSelect(event: Event): void {
		const input = event.target as HTMLInputElement;
		const file = input.files && input.files[0];
		if (file) {
			this.readFile(file);
		}
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 5000,
		});
	}

	private setDragAreaStyle(isDragging: boolean): void {
		const dragArea = document.querySelector('.drag-drop-area') as HTMLElement;
		if (isDragging) {
			dragArea.classList.add('dragging');
		} else {
			dragArea.classList.remove('dragging');
		}
	}

	private readFile(file: File): void {
		const reader = new FileReader();
		reader.onload = () => {
			const csvData = reader.result as string;
			this.fileContent = csvData;
			this.parsedData = this.parseCSV(csvData);
			this.createPreviewFromData();
		};
		reader.onerror = () => {
			console.error('Error reading file');
		};
		reader.readAsText(file);
	}

	private parseCSV(csvData: string): ArCommissionStatusReport[] {
		const lines = csvData.split('\n').map(line => line.trim());
		const data = lines.slice(1);

		return data
		.filter(line => line)
		.map(line => {
			const values = line.split(',');

			return {
				confCode: values[0] ? values[0].trim() : '',
				reasonCode: values[1] ? values[1].trim() : '',
				amountNewInvoice: null,
				amountNewPayment: null,
			};
		});
	}
}
