import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../_services/auth.service';
import packageInfo from '../../../../package.json';
import {InfoBackendService} from '../../_services/info-backend.service';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
})

export class SidenavComponent implements OnInit {
	public navigationMap: any[];
	public partnerNav: any[];
	public open: boolean;
	authService: AuthService;
	isAdmin: boolean;
	hasAccountingRole: boolean;
	showFiller = false;
	feVersion = packageInfo.version;
	beVersion = '';

	constructor(authService: AuthService,
	            private readonly infoBackendService: InfoBackendService) {
		this.open = true;
		this.authService = authService;
		this.isAdmin = false;
		this.hasAccountingRole = false;
	}

	ngOnInit() {
		this.isAdmin = this.authService.isAdmin();
		this.hasAccountingRole = this.authService.hasAccountingRole();
		if (!this.isAdmin) {
			this.partnerNav = [];
			this.navigationMap = [{
				title: 'Admin Tools',
				mat_icon: 'settings',
				isOpen: true,
				toggle: this.toggleView,
				routes: [{
					label: 'Session Debug',
					route: '/admin/sessionDebug'
				}, {
					label: 'Deal Selection',
					route: '/admin/dealSelection'
				}, {
					label: 'Campaign Tool ',
					route: '/admin/campaign'
				}, {
					label: 'SEO Location',
					route: '/admin/seoLocation'
				}, {
					label: 'Landing Pages',
					route: '/admin/landingPage'
				}, {
					label: 'Ad Placement',
					route: '/admin/adPlacements'
				}]
			}]
		} else {
			this.partnerNav = [
				{
					title: 'Operations',
					mat_icon: 'chrome_reader_mode',
					toggle: this.toggleView,
					isOpen: true,
					routes: [{
						label: 'Location Update',
						route: '/admin/partnerLocationUpdate'
					}, {
						label: 'Supplier Mapping',
						route: '/admin/partnerSupplierMapping'
					}]
				}
			];
			this.navigationMap = [{
				title: 'Customer Service',
				mat_icon: 'business_center',
				toggle: this.toggleView,
				isOpen: true,
				routes: [{
					label: 'Email Service ',
					route: '/admin/emailService'
				}, {
					label: 'Reservation',
					route: '/admin/reservations'
				}, {
					label: 'Session Debug',
					route: '/admin/sessionDebug'
				}]
			}, {
				title: 'Finance',
				mat_icon: 'settings',
				isOpen: true,
				toggle: this.toggleView,
				routes: [{
					label: 'Expense Adjustment',
					route: '/admin/expense'
				}, {
					label: 'Insurance Adjustment',
					route: '/admin/insuranceAdjustment'
				}, {
					label: 'Revenue Adjustment',
					route: '/admin/adAdjust'
				},
				...(this.hasAccountingRole ? [{
					label: 'Accounts Receivable',
					route: 'admin/ar-invoice-list'
				},
					{
					label: 'Upload Status - Our #s Brands',
					route: '/admin/reservations/status-our-numbers'
				}, {
					label: 'Upload Status - Their #s Brands',
					route: '/admin/reservations/status-their-numbers'
				},
					{
					label: 'Accounts Payable',
					route: '/admin/ap-invoice-list'
				}] : []),
				]
			}, {
				title: 'Marketing',
				mat_icon: 'chrome_reader_mode',
				toggle: this.toggleView,
				isOpen: true,
				routes: [{
					label: 'AB Tests',
					route: '/admin/abTool'
				},
					{
						label: 'Ad Placement',
						route: '/admin/adPlacements'
					},
					{
						label: 'Brand Position',
						route: '/admin/brandPos'
					},
					{
						label: 'Campaign ',
						route: '/admin/campaign'
					},
					{
						label: 'Campaign Group',
						route: 'admin/campaignGroup'
					},
					{
						label: 'CPC',
						route: '/admin/update_cpc'
					},
					{
						label: 'Deal Selection',
						route: '/admin/dealSelection'
					},
					{
						label: 'Email Subscription',
						route: '/admin/emailSubscirption'
					},
					{
						label: 'Klaviyo',
						route: '/admin/klaviyo'
					},
					{
						label: 'Seo Car Class',
						route: '/admin/seo-car-class'
					},
					{
						label: 'SEO Location',
						route: '/admin/seoLocation'
					},
					{
						label: 'Landing Pages',
						route: '/admin/landingPage'
					}]
			}, {
				title: 'Operations',
				mat_icon: 'chrome_reader_mode',
				toggle: this.toggleView,
				isOpen: true,
				routes: [{
					label: 'Brand',
					route: '/admin/brandEdit'
				},
					{
						label: 'Location Update',
						route: '/admin/locationUpdate'
					},
					{
						label: 'Partner',
						route: '/admin/partner'
					},
					{
						label: 'Vendor',
						route: '/admin/vendorEdit'
					},
					{
						label: 'Brand Onboarding Request',
						route: 'admin/brandOnboardingRequest'
					}]
			}]
		}
	}

	public toggleView(nav): void {
		nav.isOpen = !nav.isOpen;
		if (nav.isOpen) {
			this.open = true;
		}
	}

	public toggleCollapse(): void {
		let collapse: boolean;
		this.open = !this.open;

		if (this.open) {
			collapse = true;
		}
		for (const nav of this.navigationMap) {
			nav.isOpen = collapse;
		}
	}

	public toggle(drawer) {
		this.infoBackendService.getBeVersion().subscribe(res => {
			this.beVersion = res;
		});
		drawer.toggle();
		this.showFiller = !this.showFiller;
	}
}
