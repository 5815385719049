import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatSort, MatTableDataSource} from '@angular/material';

import {Loader} from '../../_models/loader';
import {AccountsReceivableService} from '../../_services/accounts-receivable.service';
import {ArInvoiceResponse, ReservationResponse} from '../../_models/accounts-receivable.model';


@Component({
	selector: 'app-ar-invoice-list',
	templateUrl: './ar-invoice-list.component.html',
	styleUrls: ['./ar-invoice-list.component.scss']
})
export class ArInvoiceListComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort, {static: false}) sort: MatSort;
	dataSource = new MatTableDataSource<ArInvoiceResponse>();
	loader = new Loader();
	filterPaid = true;
	allInvoices: ArInvoiceResponse[] = [];
	unpaidInvoices: ArInvoiceResponse[] = [];
	displayedColumns: string[] = ['id', 'invoiceDate', 'arVendorId', 'arVendorName', 'totalAmount', 'amountPaid'];

	searchCriteria = {
		arVendorName: ''
	};

	constructor(private readonly accountsReceivableService: AccountsReceivableService,
	            private readonly router: Router) {
	}

	ngOnInit() {
		console.log('ArInvoiceListComponent initialized');
	}

	ngAfterViewInit(): void {
		this.accountsReceivableService.getAllArInvoices().subscribe({
			next: arInvoices => {
				this.loader.isLoading = false;
				this.allInvoices = arInvoices;
				this.unpaidInvoices = this.allInvoices.filter(invoice => invoice.amountPaid < invoice.totalAmount);
				this.dataSource.data = this.filterPaid ? this.unpaidInvoices : this.allInvoices;
				this.dataSource.sort = this.sort;
				this.dataSource.filterPredicate = this.createFilter();
			}
		})
	}

	createNewInvoice() {
		console.log('Creating New Invoice');
		this.router.navigate(['/admin/ar-invoice-create']);
	}

	toggleFilterPaid() {
		this.filterPaid = !this.filterPaid;
		if (this.filterPaid) {
			this.dataSource.data = this.unpaidInvoices;
		} else {
			this.dataSource.data = this.allInvoices;
		}
	}

	onRowClicked(row) {
		console.log('Row clicked:', row);
		this.router.navigate(['/admin/ar-invoice-detail/', row.id]);
	}

	filterChange() {
		this.dataSource.filter = JSON.stringify(this.searchCriteria);
	}

	private createFilter(): (data: ArInvoiceResponse, filter: string) => boolean {
		return (data: ArInvoiceResponse, filter: string) => {
					const criteria = JSON.parse(filter);

					// Filter for arVendor name
					const matchesArVendorName = criteria.arVendorName ?
						data.arVendorName.toLowerCase().includes(criteria.arVendorName.toLowerCase()) : true;

					return matchesArVendorName;
				}
	}
}
