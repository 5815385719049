import {TemplateComponent} from './admin/template-boiler/template.component';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {MatFormFieldModule, MatInputModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {DataTableModule} from 'angular5-data-table';
import {AppRoutingModule} from './app-routing.module';
import {DndModule} from 'ng2-dnd';
import {MillisToDatePipe} from './_utils/custom-pipes';
import {Util} from './_utils/util';

import {AppComponent} from './app.component';
import {AuthGuard} from './_guards/auth-guard-service';
import {HeaderComponent} from './_components/header/header.component';
import {SidenavComponent} from './_components/sidenav/sidenav.component';
import {LoginComponent} from './_components/login/login.component';
import {ExpenseAdjustmentComponent} from './admin/expense-adjustment/expense-adjustment.component';
import {BrandEditComponent} from './admin/brand-tool/brand-edit.component';
import {BrandDetailsEditComponent} from './admin/brand-tool/brand-details-edit.component';
import {BrandPositionComponent} from './admin/brand-tool/brand-pos.component';
import {VendorEditComponent} from './admin/vendor-tool/vendor-edit.component';
import {BrandCpcEditorComponent} from './admin/brand-tool/brand-cpc-editor.component';
import {AdPlacementComponent} from './admin/ad-placement-tool/ad-placement-tool.component';
import {SeoCarComponent} from './admin/seo-car-class/seo-car.component';
import {SeoCarEditComponent} from './admin/seo-car-class/seo-car-edit.component';
import {SeoCarFaqEditComponent} from './admin/seo-car-class/seo-car-faq-edit.component';
import {BrandCpcEditDialogComponent} from './admin/brand-tool/brand-cpc-edit-dialog.component';
import {DashboardRequestComponent} from './admin/dashboard-request/dashboard-request.component';

import {AuthService} from './_services/auth.service';
import {CampaignService} from './_services/campaign-service';
import {BrandService} from './_services/brand-service';
import {PartnerApiService} from './_services/partner-api-service';
import {VendorService} from './_services/vendor-service';
import {AdPlacementService} from './_services/ad-placement-service';
import {SeoCarService} from './_services/seo-car-service';

import {HomeComponent} from './home/home.component';
import {ExpenseAdjustmentEditComponent} from './admin/expense-adjustment/expense-adjustment-edit/expense-adjustment-edit.component';
import {
	ExpenseAdjustmentHistoryComponent
} from './admin/expense-adjustment/expense-adjustment-history/expense-adjustment-history.component';
import {SessionDebugComponent} from './admin/session-debug/session-debug.component';
import {SessionDebugService} from './_services/session-debug-service';
import {AdCostAdjustmentComponent} from './admin/ad-adjustment/ad-adjustment.component';
import {AdCostAdjustmentService} from './_services/ad-adjustment-service';
import {AbToolComponent} from './admin/ab-tool/ab-tool.component';
import {AbToolService} from './_services/ab-service';
import {ChangePasswordComponent} from './admin/account-setting/change-password.component';
import {AccountService} from './_services/account-service';
import {EmailService} from './_services/email-service';
import {EmailServiceComponent} from './admin/email-service-tool/email-service.component';
import {CampaignToolComponent} from './admin/campaign-tool/campaign-tool.component';
import {SeoToolComponent} from './admin/seo-tool/seo-tool.component';
import {SeoLocationService} from './_services/seo-location-service';
import {SeoToolTabComponent} from './admin/seo-tool/seo-tool-tab.component';
import {LocationUpdateComponent} from './admin/location-update/location-update.component';
import {PartnerLocationUpdateComponent} from './admin/partner-location-update/location-update.component';
import {SeoToolFaqTabComponent} from './admin/seo-tool/seo-tool-faq-tab.component';
import {SeoFaqService} from './_services/seo-faq-service';
import {SeoLocationMappingService} from './_services/seo-location-mapping-service';
import {SeoLocationMappingTabComponent} from './admin/seo-tool/seo-location-mapping-tab.component';
import {SeoSupplierService} from './_services/seo-supplier-service';
import {SeoSuppliersTabComponent} from './admin/seo-tool/seo-suppliers-tab.component';
import {ExcelService} from './_services/excel-service';
import {ReservationToolComponent} from './admin/reservation-tool/reservation-tool.component';
import {ReservationDetailComponent} from './admin/reservation-tool/reservation-detail.component';
import {ReservationService} from './_services/reservation-service';
import {EmailSubscriptionComponent} from './admin/email-subscription/email-subscription.component';
import {DealSelectionComponent} from './admin/deal-selection/deal-selection.component';
import {DealSelectorService} from './_services/deal-selector-service';
import {UserService} from './_services/user-service';
import {PartnerToolComponent} from './admin/partner-tool/partner-tool.component';
import {PartnerService} from './_services/partner-service';
import {MarketingGroupService} from './_services/marketing-group-service';
import {KlaviyoToolComponent} from './admin/klaviyo-tool/klaviyo-tool.component';
import {KlaviyoService} from './_services/klaviyo-service';
import {CampaignGroupComponent} from './admin/campaign-group-tool/campaign-group.component';
import {CampaignGroupService} from './_services/campaign-group-service';
import {SupplierMappingComponent} from './admin/supplier-mapping-tool/supplier-mapping.component';
import {SupplierMappingService} from './_services/supplier-mapping-service';
import {SupplierService} from './_services/supplier-service';
import {CampaignGroupMappingService} from './_services/campaign-group-mapping-service';
import {PaymentDetailService} from './_services/payment-detail-service';
import {VendorDetailsEditComponent} from './admin/vendor-tool/vendor-details-edit.component';
import {InsuranceCommissionComponent} from './admin/insurance-commission-tool/insurance-commission';
import {InsuranceCommissionService} from './_services/insurance-commission-service';
import {InsuranceCommissionEditComponent} from './admin/insurance-commission-tool/insurance-commission-edit.component';
import {ConfirmationComponent} from './_components/confirmation-modal/confirmation.component';
import {AppConfigService} from './_services/app-config.service';
import {PartnerSupplierMappingComponent} from './admin/partner-supplier-mapping-tool/supplier-mapping.component';
import {QueryTimePipe} from './admin/session-debug/query-time-pipe';
import {MarketingLandingPageComponent} from './admin/marketing-landing-page-tool/marketing-landing-page.component';
import {MarketingLandingPageService} from './_services/seo-landing-page-service';
import {MarketingLandingPageDetailsEditComponent} from './admin/marketing-landing-page-tool/marketing-landing-page-details-edit.component';
import {
	MarketingLandingPageDetailsCreateComponent
} from './admin/marketing-landing-page-tool/marketing-landing-page-details-create.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {BrandOnboardingRequestComponent} from './admin/brand-onboarding-request/brand-onboarding-request.component';
import {ArInvoiceCreateComponent} from './admin/ar-invoice-create/ar-invoice-create.component';
import {ArInvoiceListComponent} from './admin/ar-invoice-list/ar-invoice-list.component';
import {ArInvoiceDetailComponent} from './admin/ar-invoice-detail/ar-invoice-detail.component';
import { ArPaymentCreateComponent } from './admin/ar-payment-create/ar-payment-create.component';
import { ArReservationReportCreateComponent } from './admin/ar-reservation-report-create/ar-reservation-report-create.component';
import { ApInvoiceListComponent } from './admin/ap-invoice-list/ap-invoice-list.component';
import {DecimalCurrencyPipe} from './_utils/decimal-currency-pipe';
import {
	ArReservationReportCreateOurNumbersComponent
} from './admin/ar-reservation-report-create-our-numbers/ar-reservation-report-create-our-numbers.component';

const appInitializerFn = (appConfig: AppConfigService) => {
	return () => {
		return appConfig.loadAppConfig();
	}
};

@NgModule({
	declarations: [
		TemplateComponent,
		AppComponent,
		HeaderComponent,
		SidenavComponent,
		LoginComponent,
		ExpenseAdjustmentComponent,
		HomeComponent,
		ExpenseAdjustmentEditComponent,
		ExpenseAdjustmentHistoryComponent,
		BrandEditComponent,
		BrandDetailsEditComponent,
		BrandPositionComponent,
		VendorEditComponent,
		VendorDetailsEditComponent,
		MillisToDatePipe,
		SessionDebugComponent,
		AdCostAdjustmentComponent,
		AbToolComponent,
		ChangePasswordComponent,
		EmailServiceComponent,
		BrandOnboardingRequestComponent,
		CampaignToolComponent,
		BrandCpcEditorComponent,
		LocationUpdateComponent,
		SeoToolComponent,
		SeoToolTabComponent,
		SeoToolFaqTabComponent,
		MarketingLandingPageComponent,
		SeoLocationMappingTabComponent,
		SeoSuppliersTabComponent,
		ReservationToolComponent,
		EmailSubscriptionComponent,
		DealSelectionComponent,
		PartnerToolComponent,
		KlaviyoToolComponent,
		CampaignGroupComponent,
		AdPlacementComponent,
		SupplierMappingComponent,
		SeoCarComponent,
		BrandCpcEditDialogComponent,
		DashboardRequestComponent,
		SeoCarEditComponent,
		SeoCarFaqEditComponent,
		ReservationDetailComponent,
		InsuranceCommissionComponent,
		InsuranceCommissionEditComponent,
		ConfirmationComponent,
		PartnerLocationUpdateComponent,
		PartnerSupplierMappingComponent,
		MarketingLandingPageDetailsEditComponent,
		MarketingLandingPageDetailsCreateComponent,
		ArInvoiceCreateComponent,
		ArInvoiceListComponent,
		ArInvoiceDetailComponent,
		ArPaymentCreateComponent,
		QueryTimePipe,
		ArReservationReportCreateComponent,
		ArReservationReportCreateOurNumbersComponent,
		ApInvoiceListComponent,
		DecimalCurrencyPipe
	],
	entryComponents: [
		BrandCpcEditDialogComponent,
		ConfirmationComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		AppRoutingModule,
		MaterialModule,
		MatFormFieldModule,
		MatInputModule,
		DataTableModule,
		AngularEditorModule,
		DndModule.forRoot()
	],
	providers: [CampaignService, BrandService, VendorService, AuthService, AuthGuard, Util, SessionDebugService,
		AdCostAdjustmentService, AbToolService, AccountService, EmailService, SeoLocationService, SeoFaqService,
		SeoLocationMappingService, SeoSupplierService, ExcelService, ReservationService, DealSelectorService,
		UserService, PartnerService, MarketingGroupService, KlaviyoService, CampaignGroupService,
		SupplierMappingService, SupplierService, AdPlacementService, CampaignGroupMappingService, SeoCarService, MarketingLandingPageService,
		PaymentDetailService, InsuranceCommissionService, PartnerApiService,
		AppConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFn,
			multi: true,
			deps: [AppConfigService]
		}
	],
	bootstrap: [AppComponent]
})

export class AppModule {
}
