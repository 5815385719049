import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'decimalCurrency'
})
export class DecimalCurrencyPipe implements PipeTransform {
	transform(value: number | string, currencyCode: string = 'USD', locale: string = 'en-US', symbolDisplay: string = 'symbol'): string {
		value = Number(value);
		const formatter = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currencyCode,
			currencyDisplay: symbolDisplay,
			minimumFractionDigits: 2,
			maximumFractionDigits: 20,
		});
		return formatter.format(value);
	}
}
