import {Component, OnInit} from '@angular/core';
import {AdCostAdjustmentService} from '../../_services/ad-adjustment-service';
import {Loader} from '../../_models/loader';
import {AdCostAdjustRequest, AdCostAdjustResult} from '../../_models/adCostAdjust';
import moment from 'moment';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-ad-adjust',
	templateUrl: './ad-adjustment.component.html',
	styleUrls: ['./ad-adjustment.component.scss'],
})

export class AdCostAdjustmentComponent implements OnInit {
	errorMsg: string;
	adCostAdjustmentService: AdCostAdjustmentService;
	authService: AuthService;
	request: AdCostAdjustRequest;
	public loader: Loader;
	_startDate: Date;
	_endDate: Date;
	_placement: string;
	_amount: number;
	_recalcCpc: boolean;
	_updateRecords: boolean;
	_adPlacements: string[];
	_costAdjustResults: AdCostAdjustResult;
	isAdmin: boolean;


	constructor(adCostAdjustmentService: AdCostAdjustmentService, authService: AuthService) {
		this.adCostAdjustmentService = adCostAdjustmentService;
		this.authService = authService;
		this._costAdjustResults = null;
		this.isAdmin = false;
	}

	ngOnInit() {
		this.loader = new Loader();
		this.errorMsg = '';
		this.isAdmin = this.authService.isAdmin();
		this.getInitParams();
	}

	public getInitParams(): void {
		this.loader.isLoading = true;
		this.adCostAdjustmentService.initParams()
			.subscribe(
				res => {
					this._adPlacements = Object.keys(res.stPlacements);
					this.request = res;
					console.log(res);
					this.resetFormData();

					this.loader.isLoading = false;
				},
				err => {
					console.log(err);
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	public recalcCost(): void {
		if (this.formValid()) {

			this.loader.isLoading = true;
			const request: any = {
				startDate: moment(this._startDate).format('YYYY-MM-DD'),
				endDate: moment(this._endDate).format('YYYY-MM-DD'),
				adjustCpc: this._recalcCpc,
				updateRecords: this._updateRecords,
				adjustmentType: this._placement,
				reportedCommission: this._amount
			};

			this.adCostAdjustmentService.adjustCost(request)
				.subscribe(
					res => {
						this.loader.isLoading = false;
						console.log(res);
						this._costAdjustResults = res;
					},
					err => {
						this.loader.isLoading = false;
						console.log('error ', err);
						this.errorMsg = JSON.parse(err._body).message;
					}
				);
		}
	}


	public formValid(): boolean {

		const oneMonthAgo = new Date();
		oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

		if (this._startDate < oneMonthAgo) {
			this.errorMsg = 'Start date must not be one month ago.';
			return false;
		}

		if (this._endDate < this._startDate) {
			this.errorMsg = 'End date must be after start date.';
			return false;
		}

		if (this._startDate.getMonth() !== this._endDate.getMonth()) {
			this.errorMsg = 'Start date and end date must be in the same month.';
			return false;
		}

		if (this._amount == null || this._amount < 0.0) {
			this.errorMsg = 'Please enter the Amount.';
			return false;
		}


		this.errorMsg = '';
		return true;
	}

	public resetFormData(): void {
		this._startDate = null;
		this._endDate = null;
		this._amount = null;
		this._recalcCpc = false;
		this._updateRecords = false;
		this._costAdjustResults = null;
	}

	public hideCostAdjustResults(event) {
		this._costAdjustResults = null;
	}
}
